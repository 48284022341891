import React from 'react';
import { Box, Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { v4 as uuidv4 } from 'uuid';
import Notifications from './Notifications';

import Animate from './Animate';
import Decorations from '../Theme/Decorations';
import MUI from './Mui';
import Stick from './Sticky';

const BASE_API_URL = process.env.REACT_APP_DBADDRESS;

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const ContentGrid = ({ content, type, params }) => {
  const typeLower = type.toLowerCase();
  return (
    <Grid
      {...['Xs', 'Sm', 'Md', 'Lg', 'Xl', 'Xxl'].reduce((acc, size, index) => {
        const propMap = ['mobile', 'tablet', 'laptop', 'desktop', 'qhd', 'uhd'];
        acc[propMap[index]] = content[`${typeLower}${size}`];
        return acc;
      }, {})}
      className={content[`${typeLower}ClassName`]}
      sx={{ textAlign: content[`${typeLower}TextAlign`] }}
    >
      <ConditionalWrapper
        condition={content[`animate${type}`]}
        wrapper={children => <Animate props={content[`animate${type}`]}>{children}</Animate>}
      >
        <ConditionalWrapper
          condition={content[`${typeLower}Sticky`]}
          wrapper={children => <Stick props={content[`${typeLower}Sticky`]} containerSelectorFocus={`.${content[`${typeLower}ClassName`]}`}>{children}</Stick>}
        >
          {content[type].map((mui) => (
            <MUI
              type={mui.__component}
              content={mui}
              params={params}
              key={uuidv4()}
            />
          ))}
        </ConditionalWrapper>
      </ConditionalWrapper>
    </Grid>
  );
};

const Boxes = ({ content, className, params }) => {
  const attrs = content.attributes;
  const backgroundColor = attrs.backgroundColor;
  const backgroundImage = attrs.backgroundImage.data ? `url(${BASE_API_URL}${attrs.backgroundImage.data.attributes.url})` : '';
  const processBackground = backgroundColor && backgroundImage ? `${backgroundImage}, ${backgroundColor}` : backgroundColor || backgroundImage;

  if (!attrs) return <Notifications error="Error: Box components content not found" />

  return (
    <Box
      className={className}
      sx={{
        background: processBackground,
        backgroundPosition: attrs.backgroundPosition,
        backgroundRepeat: attrs.backgroundRepeat,
        backgroundSize: attrs.backgroundSize,
        color: attrs.color,
        height: attrs.height,
        width: attrs.width,
        minHeight: attrs.minHeight,
        minWidth: attrs.minWidth,
        padding: attrs.padding,
        margin: attrs.margin,
        borderTop: attrs.borderTop,
        borderRight: attrs.borderRight,
        borderBottom: attrs.borderBottom,
        borderLeft: attrs.borderLeft,
        position: attrs.position,
      }}
    >
      {attrs.decorationAbove && <Decorations type={attrs.decorationAbove} />}
      <Container>
        <Grid
          container
          spacing={{
            mobile: attrs.mobileSpacing || '',
            tablet: attrs.tabletSpacing || '',
            laptop: attrs.laptopSpacing || '',
            desktop: attrs.desktopSpacing || '',
            qhd: attrs.qhdSpacing || '',
            uhd: attrs.uhdSpacing || '',
          }}
        >
          {['Single', 'Double', 'Triple'].map((type, i) => 
            attrs[type].length > 0 && <ContentGrid key={type + i || uuidv4()} content={attrs} type={type} params={params} />
          )}
        </Grid>
      </Container>
      {attrs.decorationBelow && <Decorations type={attrs.decorationBelow} />}
    </Box>
  );
};

export default Boxes;