import React, { useState, useEffect, useMemo } from 'react';
import Sticky from 'wil-react-sticky';

const Stick = ({ containerSelectorFocus, children }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const offsetTop = useMemo(() => {
    if (screenWidth <= 744) return 0;
    if (screenWidth <= 1366) return 160;
    if (screenWidth <= 1935) return 200;
    if (screenWidth <= 2300) return 210;
    if (screenWidth <= 3000) return 230;
    return 240;
  }, [screenWidth]); // Depend on the screenWidth state

  return (
    <Sticky
      containerSelectorFocus={containerSelectorFocus}
      offsetTop={offsetTop}
      className="sticky"
      stickyEnableRange={[700, Infinity]}
    >
      {children}
    </Sticky>
  );
};

export default Stick;