import { createTheme } from '@mui/material';

let styling = createTheme({
  breakpoints: {
    values: {
      xs: false,
      sm: false,
      md: false,
      lg: false,
      xl: false,
      mobile: 0,
      tablet: 744,
      laptop: 1366,
      desktop: 1935,
      qhd: 2300,
      uhd: 3000,
    },
  },
});

styling = createTheme(styling, {
  palette: {
    type: 'light',
    primary: {
      main: '#e8e8e8',
      contrastText: 'rgba(0,0,0,.6)',
      light: '#e8e8e8',
      dark: '#e8e8e8',
    },
    secondary: {
      main: 'rgba(0,168,228,0.9)',
      contrastText: 'rgba(255,255,255,0.87)',
      light: 'rgba(0,168,228,0.9)',
      dark: 'rgba(0,168,228,0.9)',
    },
    tertiary: {
      main: '#707070',
      dark: '#1d1d1d',
      light: '#dcdcdc',
    },
    error: {
      main: '#ff1507',
      light: '#ff795b',
      dark: '#da0000',
    },
    neutral: {
      main: '#FFFFFF',
      light: '#FFFFFF',
      dark: '#f0f0f0',
    },
    background: {
      default: '#fafafa',
      paper: '#ffffff',
    },
  },
  typography: {
    htmlFontSize: 30,
    fontFamily: "Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: "Futura-LT",
      fontWeight: "400 !important",
      [styling.breakpoints.between("mobile", "tablet")]: {
        fontSize: "4rem",
      },
      [styling.breakpoints.between("tablet", "laptop")]: {
        fontSize: "4.5rem",
      },
      [styling.breakpoints.between("laptop", "desktop")]: {
        fontSize: "7.7rem",
      },
      [styling.breakpoints.between("desktop", "qhd")]: {
        fontSize: "7.7rem",
      },
      [styling.breakpoints.between("qhd", "uhd")]: {
        fontSize: "8rem",
      },
      [styling.breakpoints.up("uhd")]: {
        fontSize: "13rem",
      }
    },
    h2: {
      fontFamily: "Futura-LT",
      fontWeight: "700 !important",
      [styling.breakpoints.between("mobile", "tablet")]: {
        fontSize: "3.2rem",
      },
      [styling.breakpoints.between("tablet", "laptop")]: {
        fontSize: "4rem",
      },
      [styling.breakpoints.between("laptop", "desktop")]: {
        fontSize: "5.6rem",
      },
      [styling.breakpoints.between("desktop", "qhd")]: {
        fontSize: "5.76rem",
      },
      [styling.breakpoints.between("qhd", "uhd")]: {
        fontSize: "7rem",
      },
      [styling.breakpoints.up("uhd")]: {
        fontSize: "7rem",
      }
    },
    h3: {
      fontFamily: "Futura-LT",
      fontWeight: "700 !important",
      [styling.breakpoints.between("mobile", "tablet")]: {
        fontSize: "2rem",
      },
      [styling.breakpoints.between("tablet", "laptop")]: {
        fontSize: "3.5rem",
      },
      [styling.breakpoints.between("laptop", "desktop")]: {
        fontSize: "2.9rem",
      },
      [styling.breakpoints.between("desktop", "qhd")]: {
        fontSize: "3rem",
      },
      [styling.breakpoints.between("qhd", "uhd")]: {
        fontSize: "3.5rem",
      },
      [styling.breakpoints.up("uhd")]: {
        fontSize: "4rem",
      }
    },
    h4: {
      fontFamily: 'Futura-LT',
      fontWeight: "700 !important",
      [styling.breakpoints.between("mobile","tablet")]: {

      },
      [styling.breakpoints.between("tablet","laptop")]: {
        fontSize: "2.5rem"
      },
      [styling.breakpoints.between("laptop","desktop")]: {
        fontSize: "2.5rem",
      },
      [styling.breakpoints.between("desktop","qhd")]: {
        fontSize: "2.6rem",
      },
      [styling.breakpoints.between("qhd","uhd")]: {
        fontSize: "3rem",
      },
      [styling.breakpoints.up("uhd")]: {
        fontSize: "3.5rem",
      }
    },
    h5: {
      fontFamily: 'Futura-LT',
      fontWeight: "400 !important",
      [styling.breakpoints.between("mobile", "tablet")]: {
        fontSize: "2.5rem",
      },
      [styling.breakpoints.between("tablet", "laptop")]: {
        fontSize: "3.52rem",
      },
      [styling.breakpoints.between("laptop", "desktop")]: {
        fontSize: "4rem",
      },
      [styling.breakpoints.between("desktop", "qhd")]: {
        fontSize: "5rem",
      },
      [styling.breakpoints.between("qhd", "uhd")]: {
        fontSize: "5.3rem",
      },
      [styling.breakpoints.up("uhd")]: {
        fontSize: "6rem",
      }
    },
    h6: {
      margin: 0,
      fontFamily: 'Futura-LT',
      fontWeight: "700 !important",
      [styling.breakpoints.between("mobile", "tablet")]: {
        fontSize: "2rem",
      },
      [styling.breakpoints.between("tablet", "laptop")]: {
        fontSize: "2.5rem",
      },
      [styling.breakpoints.between("laptop", "desktop")]: {
        fontSize: "3rem",
      },
      [styling.breakpoints.between("desktop", "qhd")]: {
        fontSize: "3.5rem",
      },
      [styling.breakpoints.between("qhd", "uhd")]: {
        fontSize: "3.5rem",
      },
      [styling.breakpoints.up("uhd")]: {
        fontSize: "4rem",
      }
    },
    subtitle1: {
      fontFamily: 'Futura-LT',
      lineHeight: 1.4,
      fontWeight: 400,
      [styling.breakpoints.between("mobile", "tablet")]: {
        fontSize: "1.44rem",
      },
      [styling.breakpoints.between("tablet", "laptop")]: {
        fontSize: "2.8rem",
      },
      [styling.breakpoints.between("laptop", "desktop")]: {
        fontSize: "2.72rem",
      },
      [styling.breakpoints.between("desktop", "qhd")]: {
        fontSize: "2.72rem",
      },
      [styling.breakpoints.between("qhd", "uhd")]: {
        fontSize: "2.8rem",
      },
      [styling.breakpoints.up("uhd")]: {
        fontSize: "4.56rem",
      }
    },
    subtitle2: {
      fontFamily: "Futura-LT",
      fontWeight: 400,
      [styling.breakpoints.between("mobile","tablet")]: {
        fontSize: "1.2rem",
      },
      [styling.breakpoints.between("tablet","laptop")]: {
        fontSize: "1.6rem",
      },
      [styling.breakpoints.between("laptop","desktop")]: {
        fontSize: "2rem",
      },
      [styling.breakpoints.between("desktop","qhd")]: {
        fontSize: "2.1rem",
      },
      [styling.breakpoints.between("qhd","uhd")]: {
        fontSize: "2.5rem",
      },
      [styling.breakpoints.up("uhd")]: {
        fontSize: "2.6rem",
      }
    },
    body1: {
      fontFamily: "Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif",
      lineHeight: 1.2,
      fontWeight: 400,
      fontColor: "rgba(0,0,0,0.87)",
      [styling.breakpoints.only("mobile")]: {
        fontSize: "1.425rem",
      },
      [styling.breakpoints.only("tablet")]: {
        fontSize: "2.7rem",
      },
      [styling.breakpoints.only("laptop")]: {
        fontSize: "1.6rem",
      },
      [styling.breakpoints.only("desktop")]: {
        fontSize: "1.7rem",
      },
      [styling.breakpoints.only("qhd")]: {
        fontSize: "1.96rem",
      },
      [styling.breakpoints.only("uhd")]: {
        fontSize: "2.25rem",
      }
    },
    body2: {
      fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
      fontWeight: 700,
      [styling.breakpoints.only("mobile")]: {
        fontSize: "1.425rem",
      },
      [styling.breakpoints.only("tablet")]: {
        fontSize: "2.7rem",
      },
      [styling.breakpoints.only("laptop")]: {
        fontSize: "1.6rem",
      },
      [styling.breakpoints.only("desktop")]: {
        fontSize: "1.7rem",
      },
      [styling.breakpoints.only("qhd")]: {
        fontSize: "1.96rem",
      },
      [styling.breakpoints.only("uhd")]: {
        fontSize: "2.25rem",
      }
    },
    button: {
      fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
      fontWeight: 400,
      padding: "0.75rem 1.5rem",
      color: "rgba(255,255,255,0.87)",
      [styling.breakpoints.only("mobile")]: {
        fontSize: "1.425rem",
      },
      [styling.breakpoints.only("tablet")]: {
        fontSize: "2.7rem",
      },
      [styling.breakpoints.only("laptop")]: {
        fontSize: "1.6rem",
      },
      [styling.breakpoints.only("desktop")]: {
        fontSize: "1.7rem",
      },
      [styling.breakpoints.only("qhd")]: {
        fontSize: "1.96rem",
      },
      [styling.breakpoints.only("uhd")]: {
        fontSize: "2.25rem",
      }
    },
    caption: {
      fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
      lineHeight: 1.2,
      fontWeight: "600 !important",
      fontColor: "rgba(0,0,0,0.87)",
      [styling.breakpoints.only("mobile")]: {
        fontSize: "1.425rem",
      },
      [styling.breakpoints.only("tablet")]: {
        fontSize: "2.7rem",
      },
      [styling.breakpoints.only("laptop")]: {
        fontSize: "1.6rem",
      },
      [styling.breakpoints.only("desktop")]: {
        fontSize: "1.7rem",
      },
      [styling.breakpoints.only("qhd")]: {
        fontSize: "1.96rem",
      },
      [styling.breakpoints.only("uhd")]: {
        fontSize: "2.25rem",
      }
    },
    overline: {
      fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
      lineHeight: 1.2,
      fontWeight: "400 !important",
      fontColor: "rgba(0,0,0,0.87)",
      [styling.breakpoints.only("mobile")]: {
        fontSize: "1.425rem",
      },
      [styling.breakpoints.only("tablet")]: {
        fontSize: "2.7rem",
      },
      [styling.breakpoints.only("laptop")]: {
        fontSize: "1.6rem",
      },
      [styling.breakpoints.only("desktop")]: {
        fontSize: "1.7rem",
      },
      [styling.breakpoints.only("qhd")]: {
        fontSize: "1.96rem",
      },
      [styling.breakpoints.only("uhd")]: {
        fontSize: "2.25rem",
      }
    },
    inherit: {
      fontFamily: "Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif",
      lineHeight: 1.2,
      fontWeight: 400,
      fontColor: "rgba(0,0,0,0.87)",
      [styling.breakpoints.only("mobile")]: {
        fontSize: "1.425rem",
      },
      [styling.breakpoints.only("tablet")]: {
        fontSize: "2.7rem",
      },
      [styling.breakpoints.only("laptop")]: {
        fontSize: "1.6rem",
      },
      [styling.breakpoints.only("desktop")]: {
        fontSize: "1.7rem",
      },
      [styling.breakpoints.only("qhd")]: {
        fontSize: "1.96rem",
      },
      [styling.breakpoints.only("uhd")]: {
        fontSize: "2.25rem",
      }
    },
    title1: {
      fontFamily: "Futura-LT",
      fontWeight: 400,
      [styling.breakpoints.between("mobile","tablet")]: {
        fontSize: "6.4rem",
      },
      [styling.breakpoints.between("tablet","laptop")]: {
        fontSize: "9.6rem",
      },
      [styling.breakpoints.between("laptop","desktop")]: {
        fontSize: "9.6rem",
      },
      [styling.breakpoints.between("desktop","qhd")]: {
        fontSize: "9.6rem",
      },
      [styling.breakpoints.between("qhd","uhd")]: {
        fontSize: "10.4rem",
      },
      [styling.breakpoints.up("uhd")]: {
        fontSize: "11.2rem",
      }
    },
    navigation: {
      fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
      fontWeight: 400,
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "1.25rem 2.5rem",
          borderRadius: "0.33rem",
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          height: "100%",
          display: "flex",
          borderRadius: "0.5rem",
          flexDirection: "column",
          justifyContent: "space-between",
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          height: "100%",
          position: "relative",
          padding: "2.5rem",
          borderTop: "1px solid rgba(0,0,0,0.15)",
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: "#e0e1e2",
          padding: "2rem 1.25rem",
          top: "-2.5rem",
          position: "relative",
          left: "-2.5rem",
          borderRadius: "0 0 0.5rem 0",
          [styling.breakpoints.only("mobile")]: {
            fontSize: "1.425rem",
          },
          [styling.breakpoints.only("tablet")]: {
            fontSize: "2.7rem",
          },
          [styling.breakpoints.only("laptop")]: {
            fontSize: "1.6rem",
          },
          [styling.breakpoints.only("desktop")]: {
            fontSize: "1.7rem",
          },
          [styling.breakpoints.only("qhd")]: {
            fontSize: "1.96rem",
          },
          [styling.breakpoints.only("uhd")]: {
            fontSize: "2.25rem",
          }
        }
      }
    },
    MuiContainer: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          maxWidth: '1745px',
          [styling.breakpoints.between("mobile","tablet")]: {
            maxWidth: '90%',
          },
          [styling.breakpoints.between("tablet","laptop")]: {
            maxWidth: '665px',
          },
          [styling.breakpoints.between("laptop","desktop")]: {
            maxWidth: '1280px',
          },
          [styling.breakpoints.between("desktop","qhd")]: {
            maxWidth: '1365px',
          },
          [styling.breakpoints.between("qhd","uhd")]: {
            maxWidth: '1545px',
          },
          [styling.breakpoints.up("uhd")]: {
            maxWidth: '1745px',
          }
        },
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "1rem !important",
          fontWeight: 400,
          margin: "1rem 0 0 0.5rem",
        }
      }
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          lineHeight: "1",
          [styling.breakpoints.only("mobile")]: {
            fontSize: "1.425rem",
          },
          [styling.breakpoints.only("tablet")]: {
            fontSize: "2.7rem",
          },
          [styling.breakpoints.only("laptop")]: {
            fontSize: "1.6rem",
          },
          [styling.breakpoints.only("desktop")]: {
            fontSize: "1.7rem",
          },
          [styling.breakpoints.only("qhd")]: {
            fontSize: "1.96rem",
          },
          [styling.breakpoints.only("uhd")]: {
            fontSize: "2.25rem",
          }
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#4183c4",
          textDecoration: "none",
          '&:hover': {
            color: "rgba(0,0,0,0.5)",
          }
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "rgba(0,0,0,0.67)",
          '&:hover': {
            backgroundColor: "rgba(0,0,0,0.1)",
          },
          '&.Mui-checked': {
            color: "#ff695e",
          }
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          lineHeight: 1,
          [styling.breakpoints.only("mobile")]: {
            fontSize: "1.425rem",
          },
          [styling.breakpoints.only("tablet")]: {
            fontSize: "2.7rem",
          },
          [styling.breakpoints.only("laptop")]: {
            fontSize: "1.6rem",
          },
          [styling.breakpoints.only("desktop")]: {
            fontSize: "1.7rem",
          },
          [styling.breakpoints.only("qhd")]: {
            fontSize: "1.96rem",
          },
          [styling.breakpoints.only("uhd")]: {
            fontSize: "2.25rem",
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          label: {
            color: "rgba(0,0,0,0.5)",
            padding: "0rem 0.25rem",
            "&.Mui-focused": {
              color: "rgba(0, 168, 228, 1)",
            }
          },
          input: {
            color: "rgba(0,0,0,0.87)",
            padding: "1rem 1.5rem",
          },
          "& .MuiInputBase-root": {
            fieldset: {
              border: "2px solid rgba(0,0,0,0.23)",
            },
            "& textarea": {
              color: "rgba(0,0,0,0.87)",
              padding: "1rem 1.5rem",
            },
            "&.MuiOutlinedInput-root": {
              backgroundColor: "rgba(0,0,0,0.1)",
            },
            "& .MuiOutlinedInput-input": {
              backgroundColor: "rgba(0,0,0,0.1)",
            },
            "&.MuiFilledInput-root": {
              backgroundColor: "rgba(0,0,0,0.1)",
              padding: "1rem 0 0",
            },
            "&.MuiFilledInput-root:hover::before": {
              borderBottom: "2px solid rgba(0, 168, 228, 1)"
            },
            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(0, 168, 228, 1)",
            },
            "&.MuiInput-root::before": {
              borderBottom: "2px solid rgba(0,0,0,.5)",
            },
            "&.MuiFilledInput-root::before": {
              borderBottom: "2px solid rgba(0,0,0,.25)",
            },
            "&.MuiInput-root::after": {
              borderBottom: "2px solid rgba(0, 168, 228, 1)",
            },
            "&.MuiInput-root::before:hover": {
              borderBottom: "2px solid rgba(0, 168, 228, 1)",
            },
            "&.MuiFilledInput-root::after": {
              borderColor: "rgba(0, 168, 228, 1)",
            },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(0,0,0,0.67)",
            },
          },
        }
      }
    },
  }
});

export default styling;