import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { MuiMarkdown, getOverrides } from 'mui-markdown';
import Notifications from './Notifications';
import { Breadcrumbs, Button, Card, CardActions, CardContent, CardMedia, Chip, Link, Pagination, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Article, CalendarMonth, Feed, GridView, Home, NavigateNext } from '@mui/icons-material';

const BASE_API_URL = process.env.REACT_APP_DBADDRESS;
const AUTH_HEADERS = { Authorization: `Bearer ${process.env.REACT_APP_AUTH}` };

const Blog = ({ params }) => {
  const [state, setState] = useState({
    loading: true,
    posts: [],
    category: null,
    catName: '',
    meta: [],
    type: null,
    errorMessage: null,
    pagination: { pageSize: 6, page: 1 }
  });

  const fetchPost = async (postId) => {
    return axios.get(`${BASE_API_URL}/api/posts`, {
      headers: AUTH_HEADERS,
      params: { 'filters[slug]': postId, populate: '*' },
    });
  };

  const fetchCategoryPosts = useCallback(async (categoryId) => {
    return axios.get(`${BASE_API_URL}/api/posts`, {
      headers: AUTH_HEADERS,
      params: {
        'filters[categories][slug][$contains]': categoryId,
        'pagination[pageSize]': state.pagination.pageSize,
        'pagination[page]': state.pagination.page,
        sort: 'updatedAt:desc',
        populate: '*',
      },
    });
  }, [state.pagination.pageSize, state.pagination.page]);

  const fetchCategoryName = async (categoryId) => {
    const response = await axios.get(`${BASE_API_URL}/api/categories`, {
      headers: AUTH_HEADERS,
      params: { 'filters[slug][$contains]': categoryId, populate: '*' },
    });
    return response.data.data[0];
  };

  const fetchPosts = useCallback(async () => {
    return axios.get(`${BASE_API_URL}/api/posts`, {
      headers: AUTH_HEADERS,
      params: {
        'pagination[pageSize]': state.pagination.pageSize,
        'pagination[page]': state.pagination.page,
        sort: 'updatedAt:desc',
        populate: '*',
      },
    });
  }, [state.pagination.pageSize, state.pagination.page]);

  const fetchData = useCallback(async () => {
    setState(prevState => ({ ...prevState, loading: true }));
    const { id, catid } = params;

    try {
      if (id) {
        const content = await fetchPost(id);
        setState(prevState => ({
          ...prevState,
          type: 'post',
          posts: content.data.data[0].attributes
        }));
      } else if (catid) {
        const content = await fetchCategoryPosts(catid);
        const catName = await fetchCategoryName(catid);
        setState(prevState => ({
          ...prevState,
          type: 'category',
          category: catid,
          posts: content.data.data,
          catName: catName,
          meta: content.data.meta
        }));
      } else {
        const content = await fetchPosts();
        setState(prevState => ({
          ...prevState,
          posts: content.data.data,
          meta: content.data.meta
        }));
      }
    } catch (error) {
      setState(prevState => ({ ...prevState, errorMessage: error }));
    } finally {
      setState(prevState => ({ ...prevState, loading: false }));
    }
  }, [params, fetchCategoryPosts, fetchPosts]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePageChange = (event, value) => {
    setState(prevState => ({ ...prevState, pagination: { ...prevState.pagination, page: value } }));
  };

  if (state.loading) return <Notifications loading={state.loading} />
  if (state.errorMessage) return <Notifications error={state.errorMessage} />

  return (
    <>
      <BreadcrumbsComponent params={params} posts={state.posts} category={state.category} catName={state.catName} />
      <br />
      {
        state.type === 'post' ? <PostContent posts={state.posts} params={params} /> :
        <PostsContent posts={state.posts} params={params} meta={state.meta} handlePageChange={handlePageChange} />
      }
    </>
  );
}

function BreadcrumbsComponent({ params, posts, category, catName }) {
  const linkStyle = { display: 'flex', alignItems: 'center' }; // Extracted common style for links

  return (
    <Breadcrumbs className="Breadcrumbs" aria-label="breadcrumb" separator={<NavigateNext fontSize="small" />}>
      <Link underline="hover" sx={linkStyle} color="inherit" href="/">
        <Home sx={{ mr: 0.5 }} fontSize="inherit" /> Home
      </Link>
      <Link underline="hover" sx={linkStyle} color="inherit" href={`/${params.page}`}>
        <Feed sx={{ mr: 0.5 }} fontSize="inherit" /> Blog
      </Link>
      {posts.title && (
        <Typography>
          <Article sx={{ mr: 0.5 }} fontSize="inherit" /> {posts.title}
        </Typography>
      )}
      {category && (
        <Link underline="hover" sx={linkStyle} color="inherit" href={`/${params.page}/${category}`}>
          <GridView sx={{ mr: 0.5 }} fontSize="inherit" /> {catName.attributes.name}
        </Link>
      )}
    </Breadcrumbs>
  );
}

function PostContent({ posts, params }) {
  return(
    <>
      <div className="Post">
        <Typography variant="h5">
          {posts.title}
        </Typography>
        <br />
        <Chip
          icon={<CalendarMonth />}
          label={'UPDATED: ' + new Date(posts.updatedAt).toLocaleString()}
          color="primary"
          size="small"
        />
        <br />
        <br />
        {posts.featuredImage.data && (
          <React.Fragment>
            <img
              src={BASE_API_URL + posts.featuredImage.data.attributes.url}
              alt={posts.featuredImage.data.attributes.alternativeText}
              width="90%"
              height="auto"
              className="centered"
            />
            <br />
          </React.Fragment>
        )}
        <MuiMarkdown
          options={{
            overrides: {
              ...getOverrides(),
            }
          }}
        >{posts.content}</MuiMarkdown>
        <br />
        <br />
        {posts.categories.data.map((category) => (
          <Chip
          icon={<GridView />}
          label={category.attributes.name}
          component="a"
          href={'/' + params.page + '/category/' + category.attributes.slug}
          color="secondary"
          size="small"
          key={category.id}
          clickable
        />
        ))}
      </div>
    </>
  );
}

function PostsContent({ posts, params, meta, handlePageChange }) {
  console.log(posts);
  return(
    <>
      <Grid container spacing={5}>
        {posts.map((post) => (
          <Grid mobile={12} tablet={12} laptop={6} key={post.id}>
            <Card variant="outlined" elevation={0}>
              {post.attributes.featuredImage.data === null ? (
                ''
              ) : (
                <Link href={'/' + params.page + '/post/' + post.attributes.slug}>
                  <CardMedia
                    component={processImgComp(post.attributes.featuredImage.data.attributes.mime)}
                    height="250"
                    image={BASE_API_URL + post.attributes.featuredImage.data.attributes.url}
                    alt={post.attributes.featuredImage.data.attributes.alternativeText}
                  />
                </Link>
              )}
              <CardContent>
                <Chip
                  icon={<CalendarMonth />}
                  label={'UPDATED: ' + new Date(post.attributes.updatedAt).toLocaleString()}
                  color="primary"
                  size="small"
                />
                <Typography variant="h3">
                  {post.attributes.title}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  <br />
                  {post.attributes.excerpt}...
                </Typography>
                <div className="postfade"></div>
              </CardContent>
              <CardActions disableSpacing>
                <Button
                  href={'/' + params.page + '/post/' + post.attributes.slug}
                  variant="outlined"
                  size="large"
                  disableElevation 
                  fullWidth
                >
                  Read More
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <br />
      <br />
      <PaginationRender meta={meta} handlePageChange={handlePageChange} />
    </>
  );
}

function PaginationRender({meta, handlePageChange}) {
  return(
    <Pagination
      count={meta.pagination.pageCount}
      page={meta.pagination.page}
      onChange={handlePageChange}
      variant="outlined"
      shape="rounded"
      size="large"
    />
  );
}

function processImgComp(imgMimeType) {
  return ['image/webp', 'image/png', 'image/jpg', 'image/jpeg', 'image/avif', 'image/webp'].includes(imgMimeType) ? 'img' : 'video';
}

export default Blog;