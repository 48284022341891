import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Link, Typography } from '@mui/material';
import GridViewIcon from '@mui/icons-material/GridView';
import Notifications from './Notifications';

const BASE_API_URL = process.env.REACT_APP_DBADDRESS;
const AUTH_HEADERS = { Authorization: `Bearer ${process.env.REACT_APP_AUTH}` };

const BlogSideBar = ({ params }) => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (params.id) {
      fetchPost(params.id)
        .then(response => setPost(response.data.data[0].attributes))
        .catch(err => setError(err))
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [params.id]);

  useEffect(() => {
    fetchCategories()
      .then(response => setCategories(response.data.data))
      .catch(err => setError(err));
  }, []);

  const fetchPost = async (postId) => {
    return axios.get(`${BASE_API_URL}/api/posts`, {
      headers: AUTH_HEADERS,
      params: { 'filters[slug]': postId, populate: 'deep' },
    });
  };

  const fetchCategories = async () => {
    return axios.get(`${BASE_API_URL}/api/categories`, {
      headers: AUTH_HEADERS,
      params: { populate: '*' },
    });
  };

  const imageStyle = { borderRadius: '1rem' };
  
  if (loading) return <Notifications loading={loading} />;
  if (error) return <Notifications error={error.message} />;

  return (
    <>
      {post?.author.data && (
        <Box mb={2}>
          <Typography variant="h3" gutterBottom>Author</Typography>
          <Box component="div" mb={2}>
            <img
              src={`${BASE_API_URL}${post.author?.data.attributes.picture.data.attributes.url}`}
              alt={post.author?.data.attributes.picture.data.attributes.alternativeText}
              style={imageStyle}
            />
            <br /><br />
            <Typography variant="h4" component="div">
              {post.author?.data.attributes.firstname} {post.author?.data.attributes.lastname}
            </Typography>
            <Typography variant="subtitle2">
              {post.author?.data.attributes.title}
            </Typography>
          </Box>
        </Box>
      )}
      <Typography variant="h4">Categories</Typography>
      <Box component="div">
        {categories.map(category => (
          category.attributes.posts.data.length > 0 && (
            <Link
              key={category.id}
              display="block"
              variant="body1"
              href={`/${params.page || 'blog'}/category/${category.attributes.slug}`}
            >
              <GridViewIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {category.attributes.name} ({category.attributes.posts.data.length})
            </Link>
          )
        ))}
      </Box>
    </>
  );
};

export default BlogSideBar;