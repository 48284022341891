import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ExpandMore } from '@mui/icons-material';

const sidebar = [];
const subs = [];

const Anchor = ({ content }) => {
    const id = content.name.replace(/\s/g, '');
    const subId = content.submenu ? content.submenu.replace(/\s/g, '') : '';
    const { name, submenu } = content;

    if(!content.submenu) {
      sidebar.push({ id, name, submenu, type: 'anchor' });
    } else {
      sidebar.push({ id: subId, name: submenu, type: 'submenu' });
    }

    if(content.submenu) subs.push({ id, name, submenu: subId });

    return <div id={id} name={name} className="navpoint" aria-hidden="true"></div>;
};

const SideBar = ({ content }) => {
  const uniqueValueSet = new Set();

  const cleanSidebar = sidebar.filter((obj) => {
    const isPresentInSet = uniqueValueSet.has(obj.name);
    uniqueValueSet.add(obj.name);
    return !isPresentInSet;
  });

  const links = cleanSidebar.map((nav, index) => {
    if(nav.type === 'anchor') {
      return(
        <Typography component="span" variant="body1" key={uuidv4()}>
          <AnchorLink href={`#${nav.id}`}>
            {nav.name}
          </AnchorLink>
        </Typography>
      );
    } else {
      return (
        <Accordion className="submenu" disableGutters square defaultExpanded key={uuidv4()}>
          <Typography component="span" variant="body1">
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panelia-content" id={`panelia-header-${index}`}>
              {nav.name}
            </AccordionSummary>
          </Typography>
          <AccordionDetails>
            <Grid container direction="column">
              {subs.map((sub) => {
                if (sub.submenu === nav.id) {
                  return(
                    <ListItem key={uuidv4()} button>
                      <Typography component="span" variant="body1">
                        <AnchorLink href={`#${sub.id}`} className="MuiTypography-root MuiTypography-body1">
                          {sub.name}
                        </AnchorLink>
                      </Typography>
                    </ListItem>
                  );
                }
                return null;
              })}
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    }
  });

  return (
    <>
      <Typography variant="h4">{content.name}</Typography>
      <br />
      <Stack spacing={1}>{links}</Stack>
      {content.children}
    </>
  );
};

export { Anchor, SideBar };