import React, { useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha"
import { FormControl, RadioGroup, InputAdornment, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import MUI from './Mui';
import Notifications from './Notifications';

const BASE_API_URL = process.env.REACT_APP_DBADDRESS;

const MUIForm = ({ content }) => {
  const form = content.form?.data?.attributes;

  const [{ disabled, loading, success, error, errMessage, inputs, page }, setState] = useState({
    loading: false,
    success: false,
    error: false,
    errMessage: 'Error message',
    inputs: {},
    page: '',
  });
  const recaptcha = useRef();

  if (loading) return <Notifications loading={loading} />

  const handleChange = (e) => {
    const updatedInputs = { ...inputs, [e.target.name]: e.target.value };
    setState(prev => ({ ...prev, inputs: updatedInputs }));
  };

  const handleDB = async () => {
    setState(prev => ({ ...prev, loading: true, disabled: true}));

    let text = Object.entries(inputs)
      .map(([key, value]) => `${key[0].toUpperCase() + key.substring(1).replace('-', ' ')}: ${value}`)
      .join('\n');

    try {
      await axios.post(`${BASE_API_URL}/api/email`, {
        to: form.to || process.env.EMAIL_DEFAULT_TO,
        from: form.from || process.env.EMAIL_DEFAULT_FROM,
        'reply-to': form.replyTo || process.env.EMAIL_DEFAULT_REPLY,
        subject: form.subject || `Website Contact Form: ${page}`,
        text
      },{
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_AUTH}`,
          'content-type': 'application/json',
        }
      });

      setState(prev => ({ ...prev, loading: false, success: true, error: false }));
    } catch (err) {
      setState(prev => ({ ...prev, loading: false, error: true, errMessage: `[${err.response.data.error.status}] ${err.response.data.error.message}` }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const captchaValue = recaptcha?.current.getValue()
    if(!captchaValue) {
      console.log('ERROR: Please select reCAPTCHA!');
    } else {
      setState(prev => ({ ...prev, page: e.target.attributes.from.value, loading: true }));
      handleDB();
    }
  };

  return (
    <>
      {!success && (
        <form
          className={`Form ${form.className}`}
          onSubmit={!form.action? handleSubmit : null}
          from={form.subject}
          action={form.action}
          method={form.method}
          target={form.target}
        >
          {form.inputs.map((mui, index) => {
            const fieldId = mui.Label?.replace(/\s/g, '-').toLowerCase();

            let width = '32.03%',
                fullWidth = null;

            if(mui.width === 'Quarter') { width = "24%"; }
            else if(mui.width === 'Third') { width = "32.33%"}
            else if(mui.width === 'Half') { width = "49%" }
            else if(mui.width === 'Full') { width = "99%"; fullWidth = true; }

            const getInputStyles = () => ({
              width,
              marginRight: '1%',
              label: {
                color: mui.labelFontColor,
                padding: "0rem 0.25rem",
                "&.Mui-focused": {
                  color: mui.fieldFocused,
                }
              },
              input: {
                color: mui.inputFontColor,
                padding: "1rem 1.5rem",
              },
              "& .MuiInputBase-root": {
                fieldset: {
                  border: mui.fieldBorder,
                },
                "& textarea": {
                  color: mui.inputFontColor,
                },
                "& .MuiOutlinedInput-input": {
                  backgroundColor: mui.fieldBgColor,
                },
                "&.MuiFilledInput-root": {
                  backgroundColor: mui.fieldBgColor,
                  padding: "1rem 0 0",
                },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: mui.fieldFocused,
                },
                "&.MuiInput-root::before": {
                  borderBottom: mui.fieldBorder,
                },
                "&.MuiFilledInput-root::before": {
                  borderBottom: mui.fieldBorder,
                },
                "&.MuiInput-root::after": {
                  borderColor: mui.fieldFocused,
                },
                "&.MuiFilledInput-root::after": {
                  borderColor: mui.fieldFocused,
                },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: mui.fieldHover,
                },
              },
            });
          
            const commonProps = {
              id: fieldId,
              name: mui.name || '',
              value: mui.defaultValue || inputs[fieldId],
              onChange: handleChange,
              autoFocus: mui.autoFocus || false,
            };
          
            const visualProps = {
              label: mui.Label || '',
              variant: mui.Variant || 'outlined',
              color: mui.color || '',
              margin: mui.margin || 'dense',
              multiline: Boolean(mui.multiline),
              rows: mui.maxRows || 1,
              hiddenLabel: Boolean(mui.hiddenLabel),
              size: mui.size || undefined,
              placeholder: mui.placeholder || '',
              helperText: mui.helperText || '',
              fullWidth,
              disabled: disabled,
              required: mui.required || false,
              InputProps: {
                startAdornment: mui.startAdornment ? (<InputAdornment position="start">{mui.startAdornment}</InputAdornment>) : '',
                endAdornment: mui.endAdornment ? (<InputAdornment position="end">{mui.endAdornment}</InputAdornment>) : '',
              },
              sx: getInputStyles(),
            };

            const processInputs = () => {
              if(mui.__component === 'mui.input') {
                if (mui.hidden) return <input type="hidden" {...commonProps} />;
                else return <TextField {...commonProps} {...visualProps} />;
              }
              else if(mui.__component === 'mui.radio-group') {
                return(
                  <FormControl>
                    <RadioGroup
                      row={mui.row || false}
                      name={mui.name || ''}
                      sx={{
                        width: '100%',
                      }}
                      onChange={handleChange}
                      disabled={disabled}
                      required={mui.required}
                    >
                      {mui.inputs.map((radio, index) => {
                        return(
                          <MUI
                            type={'mui.radio'}
                            content={radio}
                            key={radio.id + index}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                );
              }
              else if (mui.__component === 'mui.re-captcha') {
                return(
                  <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={recaptcha} />
                );
              }
              else if(mui.__component === 'mui.date-picker') {
                return(
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      name={mui.name}
                      label={mui.label}
                      onChange={(date) => handleChange({ target: { name: mui.name, value: `${date.$M + 1}/${date.$D}/${date.$y}` }})}
                      disabled={disabled}
                      required={mui.required}
                    />
                  </LocalizationProvider>
                );
              }
              else {
                return(
                  <MUI
                    type={mui.__component}
                    content={mui}
                    onChange={handleChange}
                  />
                );
              }
            }

            return(
              <React.Fragment key={index}>
                {processInputs()}
              </React.Fragment>
            );
          })}
        </form>
      )}

      {success && (
        <>
          <MUI
            type={'mui.alert'}
            content={form.successAlert}
          />
          <br />
          <br />
          {form.afterSuccess.map((mui) => {
            return(
              <MUI
                type={mui.__component}
                content={mui}
                key={uuidv4()}
              />
            );
          })}
        </>
      )}
      {error && (
        <MUI
          type={'mui.alert'}
          content={form.errorAlert}
          error={errMessage}
        />
      )}
    </>
  );
};

export default MUIForm;