import React, { useState } from 'react';
import { MuiMarkdown, getOverrides } from 'mui-markdown';
import { Alert, AlertTitle, Collapse, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const MUIAlert = ({ content, error }) => {
  const [open, setOpen] = useState(true);

  return (
    <Collapse in={open}>
      <Alert
        variant={content.variant}
        severity={content.severity}
        icon={content.icon}
        open={open}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
        sx={{
          bgcolor: content.bgcolor
        }}
      >
        {content.title && (<AlertTitle>{content.title}</AlertTitle>)}
        <MuiMarkdown
          options={{
            overrides: {
              ...getOverrides(),
              p: {
                component: 'span',
              },
              span: {
                component: 'span',
              }
            }
          }}
        >
          {content.message && (content.message)}
        </MuiMarkdown>
        {error && (error)}
      </Alert>
    </Collapse>
  );
};

export default MUIAlert;