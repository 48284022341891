import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Card, CardActions, CardContent, CardHeader } from '@mui/material';

import MUI from './Mui';

const MUICard = ({ content }) => {
  return (
    <Card
      sx={{
        backgroundColor: content.bgcolor || 'transparent',
        border: content.border,
        margin: content.margin,
        padding: content.padding,
      }}
      raised={content.raised}
      square={content.square}
      variant={content.variant}
      className={`Card`}
    >
      {content.header && (
        <CardHeader
          avatar={
            content.avatar && (
              <MUI
                type={'mui.avatar'}
                content={content.avatar}
              />
            )
          }
          title={
            content.headerTitle && (
              <MUI
                type={'mui.typography'}
                content={content.headerTitle}
              />
            )
          }
          sx={{
            margin: content.headerMargin,
            padding: content.headerPadding,
            border: content.headerBorder,
            borderWidth: content.headerBorderWidth,
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        />
      )}
      {content.cardContent.length > 0 && (
        <CardContent>
          {content.cardContent.map((content) => {
            return(
              <MUI
                type={'mui.rich-text'}
                content={content}
                key={uuidv4()}
              />
            )
          })}
        </CardContent>
      )}
      {content.cardActions.length > 0 && (
        <CardActions
          sx={{
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
            textAlign: 'center',
          }}
        >
          {content.cardActions.map((action) => {
            return(
              <MUI
                type={'mui.buttons'}
                content={action}
                key={uuidv4()}
              />
            )
          })}
        </CardActions>
      )}
    </Card>
  );
};

export default MUICard;