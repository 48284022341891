import React, { useMemo } from 'react';
import MuiMarkdown from 'mui-markdown';
import { v4 as uuidv4 } from 'uuid';
import { Box, Container, Typography } from '@mui/material';
import Notifications from './Notifications';

import MUI from './Mui';

const BASE_API_URL = process.env.REACT_APP_DBADDRESS;

const Banner = ({ content, sizing }) => {
  const { attributes: banner } = content.data;
  const bannerMedia = useMemo(() => banner.background.data?.attributes, [banner]);
  const bannerType = useMemo(() => getBannerType(bannerMedia), [bannerMedia]);
  const picBg = useMemo(() => getPicBg(bannerMedia), [bannerMedia]);
  const titleBoxImage = useMemo(() => getTitleBoxImage(banner), [banner]);

  if (!banner) return <Notifications error="Error: Banner content not found" />

  return (
    <Box className={`Banner ${bannerType} ${banner.className || ''}`} sx={{ background: picBg, backgroundColor: banner.bgColor, ...getBannerSizing(banner) }}>
      <ContentOverlay sizing={sizing} content={banner.content} />
      {bannerType === 'video' && <BannerVideo bannerMedia={bannerMedia} />}
      {bannerType === 'picture' && banner.titleBox && <BannerPicture titleBoxImage={titleBoxImage} banner={banner} sizing={sizing} />}
    </Box>
  );
};

const getBannerType = (bannerMedia) => {
  if (!bannerMedia) return 'plain';
  if (bannerMedia.mime.includes('video')) return 'video';
  if (bannerMedia.mime.includes('image')) return 'picture';
  return 'plain';
};

const getPicBg = (bannerMedia) => {
  const backgroundImageUrl = bannerMedia ? `${BASE_API_URL}${bannerMedia.url}` : '';
  return backgroundImageUrl ? `linear-gradient(rgba(0,0,0,.55),rgba(0,0,0,.3)),url(${backgroundImageUrl})` : '';
};

const getTitleBoxImage = (banner) => {
  return banner.titleBox ? `${BASE_API_URL}${banner.titleBoxImage.data.attributes.url}` : '';
};

const getBannerSizing = (banner) => ({
  height: banner.height,
  minHeight: banner.minHeight,
  maxHeight: banner.maxHeight,
});

const ContentOverlay = React.memo(({ sizing, content }) => (
  <Box className="overlay">
    <Container sx={sizing} maxWidth={false} className="content">
      {content.map((mui, index) => (
        <MUI
          type={mui.__component}
          content={mui}
          className={mui.className}
          key={mui.id + '_' + index || uuidv4()}
        />
      ))}
    </Container>
  </Box>
));

const BannerVideo = React.memo(({ bannerMedia }) => (
  <video playsInline autoPlay muted loop>
    <source src={`${BASE_API_URL}${bannerMedia.url}`} type={bannerMedia.mime} />
  </video>
));

const BannerPicture = React.memo(({ titleBoxImage, banner, sizing }) => (
  <Box className="BannerTitle">
    <Container sx={sizing} maxWidth={false}>
      {titleBoxImage && <img src={titleBoxImage} alt={banner.titleBoxTitle.text} />}
      <BannerText {...banner.titleBoxTitle} />
      <BannerText {...banner.titleBoxSub} />
    </Container>
  </Box>
));

const BannerText = React.memo(({ align, classes, component, variant, gutterBottom, noWrap, paragraph, color, text }) => (
  <Typography
    align={align}
    className={classes}
    component={component}
    variant={variant}
    gutterBottom={gutterBottom}
    noWrap={noWrap}
    paragraph={paragraph}
    sx={{ color }}
  >
    <MuiMarkdown
      overrides={{
        span: {
          component: '',
        }
      }}
    >{text}</MuiMarkdown>
  </Typography>
));

export default React.memo(Banner);