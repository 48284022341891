import React from 'react';
import { MuiMarkdown, getOverrides } from 'mui-markdown';
import { Typography } from '@mui/material';

const MUITypography = ({ content }) => {

  return (
    <Typography
      align={content.align || 'inherit'}
      className={content.className}
      component={content.comp || 'span'}
      variant={content.variant || 'body1'}
      gutterBottom={content.gutterBottom || false}
      noWrap={content.noWrap || false}
      paragraph={content.paragraph || false}
      sx={{
        color: content.color || '',
        fontWeight: content.fontWeight || '400',
      }}
    >
      <MuiMarkdown
        options={{
          overrides: {
            ...getOverrides(),
            p: {
              component: 'span',
            },
            span: {
              component: 'span',
            }
          }
        }}
      >
        {content.text}
      </MuiMarkdown>
    </Typography>
  );
};

export default MUITypography;